.App {
	text-align: center;
  
  }
  #root {
	height: 100vh;
	background: white;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
} 